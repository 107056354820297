import { Box, Grid, Paper, Stack, Typography, lighten, styled, useTheme } from "@mui/material";
import { ChangePasswordForm } from "@sal/portal";
import mvgl_logo from "../../public/images/mvgl_logo_alt.png";

const StyledContainer = styled("div")(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		width: 300
	},
	[theme.breakpoints.up("sm")]: {
		width: 350
	},
	[theme.breakpoints.up("lg")]: {
		width: 550
	}
}));

export function ChangePasswordPage() {
	const theme = useTheme();

	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			style={{
				minHeight: "100vh",
				backgroundColor: theme.palette.primary.main
			}}
		>
			<Stack
				spacing={12}
				useFlexGap
				flexWrap="wrap"
				direction="column"
				justifyContent="space-between"
				alignItems="center"
				sx={{ paddingTop: theme.spacing(12) }}
			>
				<Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
					<StyledContainer>
						<Box
							sx={{
								paddingBottom: "2.5rem",
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<img style={{ maxWidth: "60%", maxHeight: "20rem" }} src={mvgl_logo} alt="Logo" />
						</Box>
						<Paper
							elevation={0}
							sx={{
								padding: theme.spacing(10),
								backgroundColor: lighten(theme.palette.primary.main, 0.1)
							}}
						>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								spacing={4}
								style={{
									color: theme.palette.common.white,
									paddingLeft: "1rem"
								}}
							>
								<Grid item>
									<Typography
										variant="h5"
										align="center"
										style={{
											color: "inherit",
											paddingTop: theme.spacing(2),
											paddingBottom: theme.spacing(8),
											paddingRight: theme.spacing(4)
										}}
									>
										{"Change Password"}
									</Typography>
								</Grid>
								<ChangePasswordForm inputLabelStyles={{ color: theme.palette.common.white }} />
							</Grid>
						</Paper>
					</StyledContainer>
				</Grid>
			</Stack>
		</Grid>
	);
}
