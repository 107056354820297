export const EventCode = {
	TrackingEvents: [
		"Created",
		"SLIReceived",
		"Approved",
		"ArrivedAtCFS",
		"ExportClearance",
		"BookingRequested",
		"BookingConfirmed",
		"EmptyContainerDispatched",
		"ContainersPacked",
		"ContainersGated",
		"CargoAtAirTerminal",
		"ContainersOnboard",
		"ConfirmedOnFlight",
		"DocumentsRequested",
		"VesselArrivedAtTranshipmentPort",
		"VesselDepartedAtTranshipmentPort",
		"CoastalVesselArrived",
		"CoaslalVesselDeparture",
		"OriginalBillOfLoadingRequired",
		"OriginalBillOfLoadingReceived",
		"Invoiced"
	],
	CustomsAndQuarantineEvents: [
		"CustomsEntryCreated",
		"QuarantineHoldPlaced",
		"QuarantineHoldReleased",
		"CustomsHoldPlaced",
		"CustomsHoldReleased",
		"ImportClearance"
	],
	TransportEvents: [
		"Discharged",
		"AvailableAtWharf",
		"DischargedFromAircraft",
		"GateOut",
		"SentToGroundHandlingAgent",
		"ContainerMovedViaYard",
		"CargoAvailable",
		"DeliveryRequested",
		"TransportAdvised",
		"DeliveryBooked",
		"Delivered",
		"ContainerEmptyCollectionRequested",
		"EmptyBooked",
		"EmptyContainerCollected",
		"ContainerDehired"
	]
} as const;
