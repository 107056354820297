import { createTheme, darken } from "@mui/material/styles";

export const theme = createTheme(
	{
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: {
						backgroundColor: "#f7f7f7"
					}
				}
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: "#ffffff",
						boxShadow: "none"
					}
				}
			},
			MuiAutocomplete: {
				styleOverrides: {
					inputRoot: {
						padding: 0
					}
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: "none"
					},
					containedPrimary: {
						"&:hover": {
							backgroundColor: darken("#0a2b6f", 0.1)
						}
					},
					containedSecondary: {
						"&:hover": {
							backgroundColor: darken("#a2ad00", 0.1)
						}
					},
					outlinedPrimary: {
						borderWidth: "2px",
						borderColor: "#0a2b6f",
						"&:hover": {
							borderWidth: "2px",
							borderColor: "#0a2b6f"
						}
					}
				}
			},
			MuiFormControl: {
				styleOverrides: {
					root: {
						backgroundColor: "#ffffff",
						borderColor: "#f7f7f7"
					}
				}
			},
			MuiCardContent: {
				styleOverrides: {
					root: {
						":last-child": {
							paddingBottom: "1rem"
						}
					}
				}
			},
			MuiTab: {
				styleOverrides: {
					root: {
						textTransform: "capitalize"
					}
				}
			}
		},
		palette: {
			primary: {
				main: "#0a2b6f"
			},
			secondary: {
				main: "#aab733"
			},
			background: {
				default: "#f7f7f7",
				paper: "#ffffff"
			},
			success: {
				main: "#aab733"
			},
			warning: {
				main: "#f9b42d"
			}
		},
		typography: {
			allVariants: {
				fontFamily: "Inter, sans-serif"
			},
			h4: {
				fontWeight: 600
			},
			h5: {
				fontWeight: 500,
				fontSize: "1rem"
			},
			h6: {
				fontSize: "0.75rem",
				color: "#9e9e9e"
			}
		},
		spacing: (factor: number) => `${0.25 * factor}rem`
	},
	{
		portalFramework: {
			pages: {
				login: {
					backgroundContainer: {}
				}
			}
		}
	}
);
