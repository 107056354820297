import { createRoot } from "react-dom/client";
import { PortalFramework, Settings } from "@sal/portal";
import { PortalApplicationContext } from "@sal/onevent-portal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import Routes from "./Routes";
import { Settings as settings } from "./Settings";
import { theme } from "./globalTheme";
import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "./locale";

LicenseInfo.setLicenseKey(process.env.LICENSE_MUI!);

const pfSettings: Settings = {
	logoUrl: "../images/mvgl_logo.svg",
	loginTitle: "Log into your account",
	defaultColorMode: "system",
	microsoftSsoSettings: settings.loginProps.microsoftSsoSettings
};

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false
		},
		mutations: {
			retry: false
		}
	}
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
	<PortalFramework settings={pfSettings}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<QueryClientProvider client={queryClient}>
				<PortalApplicationContext>
					<Routes />
				</PortalApplicationContext>
			</QueryClientProvider>
		</ThemeProvider>
	</PortalFramework>
);
