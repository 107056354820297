import { Box, Grid, Typography, useTheme } from "@mui/material";
import { MenuItem, MobileMenuDialog } from "@sal/portal";
import { MenuOutlined } from "@mui/icons-material";
import { useAuth } from "@sal/authentication.react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

type ProvidePermissionResult = (auth: ReturnType<typeof useAuth>) => boolean;

type MobileNavigationPermissions = {
	// menu: ProvidePermissionResult;
	userMenu: ProvidePermissionResult;
	changePassword: ProvidePermissionResult;
};

export interface MobileNavigationProps {
	permissions: MobileNavigationPermissions;
	clientLogo?: string;
	menuItems: Array<MenuItem>;
}

const EMPTY_STRING = "";

export function MobileNavigation({ permissions, menuItems }: MobileNavigationProps) {
	const theme = useTheme();
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const location = useLocation();
	const currentPathname = location.pathname;
	const currentLabel =
		menuItems.find((x) => x.path === currentPathname)?.label ??
		(location.pathname ? location.pathname.split("/")[1] : EMPTY_STRING);

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			padding={4}
			sx={{
				height: "3.5rem",
				maxHeight: "3.5rem",
				backgroundColor: (color) => color.palette.primary.main
			}}
		>
			<Box display="flex" alignItems="center" gap={4} color={theme.palette.common.white} lineHeight={1}>
				{<MenuOutlined color="inherit" onClick={() => setMenuOpen(true)} />}
				<Typography component="h1">{currentLabel}</Typography>
			</Box>

			<MobileMenuDialog
				open={menuOpen}
				setOpen={setMenuOpen}
				menuItems={menuItems}
				permissions={{
					userMenu: permissions.userMenu,
					changePassword: permissions.changePassword
				}}
			/>
		</Grid>
	);
}
