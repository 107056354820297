import { useEffect, useMemo, useState } from "react";
import {
	Autocomplete,
	AutocompleteCloseReason,
	CircularProgress,
	InputAdornment,
	Typography,
	debounce
} from "@mui/material";
import { default as TextField } from "@mui/material/TextField/TextField";
import { OnEventApiResponse } from "@origin/platform-types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { httpService } from "@sal/onevent-portal";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";

type SearchResult = {
	searchResultID: number | string;
	objectID: number | string;
	objectType: string;
	objectFunction: string;
	title: string;
	description: string;
	dataJson?: {
		referenceValue?: string;
	};
	searchResultSystemName: string;
	typeCode: string;
};

export function QuickSearch() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

	const [, setInputValue] = useState<string>("");
	const [selectValue, setSelectValue] = useState<SearchResult | null>(null);
	const applySearchTerm = useMemo(() => debounce((value: string | undefined) => setSearchTerm(value), 500), []);

	const queryOptions = useMemo(() => {
		if (searchTerm && searchTerm !== "") {
			return {
				searchTerm: searchTerm,
				searchBy: "PortalJob"
			};
		}
		return undefined;
	}, [searchTerm]);

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useQuery<OnEventApiResponse<SearchResult[]>, Error>(
		["QuickSearch"],
		({ signal }) => httpService.get("Search", queryOptions, signal),
		{
			keepPreviousData: false,
			enabled: !!searchTerm
		}
	);

	useEffect(() => {
		if (queryOptions) {
			refetch();
		}
	}, [queryOptions]);

	useEffect(() => {
		if (searchTerm === "") {
			queryClient.resetQueries({ queryKey: ["QuickSearch"], exact: true });
		}
	}, [searchTerm]);

	const handleOnChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: SearchResult | null
	) => {
		if (value) {
			let url = "#";
			switch (value?.objectType) {
				case "PortalShipment":
					url = `/Shipments?q=${value.objectID}&intent=tab`;
					break;
				case "PortalOrder":
					url = `/Orders?q=${value.objectID}&intent=tab`;
					break;
				default:
					break;
			}
			navigate(url);
		}
	};

	const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: SearchResult) => {
		return (
			<li {...props} key={`${option.searchResultID}_${option.objectID}`}>
				<Typography fontSize="0.85rem" lineHeight="0.9rem">
					{option.description}
				</Typography>
			</li>
		);
	};

	const getOptionLabel = (option: SearchResult) => {
		return option.dataJson?.referenceValue || "";
	};

	const handleOnClose = (event: React.SyntheticEvent, reason: AutocompleteCloseReason) => {
		if (reason === "selectOption") {
			setSelectValue(null);
			setInputValue("");
		}
	};

	return (
		<Autocomplete<SearchResult, false, false, false>
			value={selectValue}
			fullWidth
			clearOnBlur={false}
			loading={isLoading}
			options={data?.data ?? []}
			filterOptions={(options) => options}
			onChange={handleOnChange}
			onClose={handleOnClose}
			onInputChange={(event, value, reason) => {
				const term = reason === "clear" ? undefined : value;
				setInputValue(value);
				applySearchTerm(term);
			}}
			getOptionLabel={getOptionLabel}
			renderOption={renderOption}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={"Search"}
					InputProps={{
						...params.InputProps,
						endAdornment: <>{isLoading && <CircularProgress color="inherit" size="1rem" />}</>,
						startAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
				/>
			)}
			sx={{
				".MuiAutocomplete-input": {
					padding: "4px 8px !important", // Align with the UserOrganisationToggle
					fontSize: "0.85rem"
				}
			}}
		/>
	);
}
