import { Box, Button, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { VerticalNavigationComponentProps } from "@sal/onevent-portal";
import { useNavigate } from "react-router-dom";
import { getTerm } from "../locale/index";
import { useAuth } from "@sal/authentication.react";

export function VerticalNavigationActionButton({ collapsed }: VerticalNavigationComponentProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	
	// NOTE - MVP we are not using permissions to control new order. For MVP users cannot create orders
	// const auth = useAuth();
	// const canCreateNewOrder = auth.userHasPermission("apI_PortalOrder", "Write");
	const canCreateNewOrder = false;

	return (
		<Box display="flex" alignItems="center" justifyContent="center" width="100%" paddingX={collapsed ? 0 : 6}>
			{canCreateNewOrder &&
				<Button
					variant="contained"
					color="primary"
					fullWidth={!collapsed}
					title={getTerm("navigation_NewOrder")}
					sx={{
						paddingY: collapsed ? theme.spacing(2) : theme.spacing(3),
						minWidth: collapsed ? "auto" : "initial"
					}}
					onClick={() => navigate({ pathname: "/Order" })}
				>
					<Typography
						fontSize={collapsed ? "large" : "0.85rem"}
						color={theme.palette.common.white}
						sx={{ display: "flex", alignItems: "center", gap: 1 }}
					>
						<AddIcon fontSize="inherit" />
						{!collapsed && <span>{getTerm("navigation_NewOrder")}</span>}
					</Typography>
				</Button>
			}
		</Box>
	);
}
