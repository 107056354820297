import { addTerms, getTerm, getTermFromStore } from "@sal/onevent-portal";
import { base } from "./base";
import { enAU } from "./enAU/";
import { enNZ } from "./enNZ/";


let browserLocale = navigator.language;

let terms = {
	"default": { ...base },
	"en-AU": { ...base, ...enAU },
	"en-NZ": { ...base, ...enNZ }
};

if (!terms[browserLocale]) {
	terms[browserLocale] = {
		...base
	};
}

addTerms(terms);

export { getTerm, getTermFromStore };
