export const SystemName = {
	ShipmentMode: {
		Air: "Air",
		Sea: "Sea"
	},
	NotificationTransportType: {
		Messaging: "Messaging",
		Email:"Email"
	},
	ScheduleJobType: {
		ShipmentSummaryNotification: "ShipmentSummaryNotification",
		OrderSummaryNotification: "OrderSummaryNotification",
		ContainerSummaryNotification: "ContainerSummaryNotification",
		StatusChangeReport: "StatusChangeReport"
	},
	ReportGroup: {
		StatusChangeReport: "StatusChangeReport"
	},
	LocationType: {
		CAFSLocation: "CAFSLocation"
	},
	SettingType: {
		JSON: "Json",
		PortalUISetting:"PortalUISetting"
	},
	DocumentType: {
		Invoice: "Invoice",
		CommercialInvoice: "CommercialInvoice",
		"CustomsEntry/Declaration": "CustomsEntry/Declaration"
	}
} as const;

export const UISettingSystemName = {
	OrderGird: "Grid_PortalOrderList",
	ShipmentGrid: "Grid_PortalShipmentList",
	ContainerGrid: "Grid_PortalContainerList",
	TrackPageMapView: "TractPage_MapView",
	TrackPageFilter: "TrackPage_Filter"
} as const;

export const NotificationTypeSystemName = {
	ShipmentCreated: "ShipmentCreated",
	ShipmentRequiresApproval: "ShipmentRequiresApproval",
	ShipmentDeparted: "ShipmentDeparted",
	ShipmentArrived:"ShipmentArrived",
	ShipmentArrivedTranshipmentPort: "ShipmentArrivedTranshipmentPort",
	ShipmentDepartedTranshipmentPort: "ShipmentDepartedTranshipmentPort",
	ShipmentArrivalWithinDay: "ShipmentArrivalWithinDay",
	ShipmentReadyForDelivery: "ShipmentReadyForDelivery",
	ShipmentOnHold: "ShipmentOnHold",
	ShipmentCargoAvailable: "ShipmentCargoAvailable",
	ShipmentSummary: "ShipmentSummary",
	OrderCreated: "OrderCreated",
	OrderCargoReady: "OrderCargoReady",
	OrderInTransit: "OrderInTransit",
	OrderStatusChanged: "OrderStatusChanged",
	OrderMissOriginalReadyDate: "OrderMissOriginalReadyDate",
	OrderCargoReadyWithinDay: "OrderCargoReadyWithinDay",
	OrderSummary:"OrderSummary",
	ContainerCollectedFromWharf: "ContainerCollectedFromWharf",
	ContainerDeliveredToWharf: "ContainerDeliveredToWharf",
	ContainerAtRisk: "ContainerAtRisk",
	ContainerDeparted: "ContainerDeparted",
	ContainerArrived:"ContainerArrived",
	ContainerLoaded: "ContainerLoaded",
	ContainerAvailable: "ContainerAvailable",
	ContainerDehired: "ContainerDehired",
	ContainerSummary: "ContainerSummary",
	ContainerCustomsClearance: "ContainerCustomsClearance",
	TransportJobBookingRequested: "TransportJobBookingRequested",
	DeliveryBooked: "DeliveryBooked",
	DeliveryOccurred: "DeliveryOccurred",
	DeliveryMissIntoStoreDate: "DeliveryMissIntoStoreDate",
	CustomClearanceConcluded: "CustomClearanceConcluded",
	InvoicePaid: "InvoicePaid",
	InvoiceOverdue: "InvoiceOverdue",
	StatementAvailable: "StatementAvailable",
	BillOfLading: "BillofLading",
	CommercialInvoice: "CommercialInvoice",
	PackingList: "PackingList",
	PackingDeclaration: "PackingDeclaration",
	CertificateOfOrigin: "CertificateofOrigin",
	CustomsEntryDeclaration: "CustomsEntry/Declaration",
	ProofOfDelivery: "ProofOfDelivery",
	StatusChangeReport: "StatusChangeReport"
} as const;
