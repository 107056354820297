export const UserSettingTypeName = {
	Preference: "Preference"
} as const;

export const ContactTypeName = {
	User: "User",
	Organisation: "Organisation"
} as const;

export const IdentityTypeName = {
	SalCore: "SALCore"
} as const;